import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Protected from "./utils/PrivateRoute"
import HomeRoute from "./utils/HomeRoute"
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Resetpassword from "./pages/Resetpassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import Organizationslist from "./pages/Organizationlist";
import AddOrganizations from "./pages/AddOrganizations";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeRoute Component={Login} />} />
        <Route path="/reset-password" element={<Resetpassword />} />
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/admin" element={<Protected Component={MainLayout} />}>
          <Route index element={<Dashboard />} />
          <Route path="Organization-list" element={<Organizationslist />} />
          <Route path="Add-Organization" element={<AddOrganizations />} />
          <Route path="Add-Organization/:id" element={<AddOrganizations />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
