const tokenFromLocalStorage = localStorage.getItem("token");
const getTokenFromLocalStorage = tokenFromLocalStorage ? tokenFromLocalStorage : null;

export const config = {
  headers: {
    Authorization: `Bearer ${
      getTokenFromLocalStorage !== null ? getTokenFromLocalStorage : ""
    }`,
    Accept: "application/json",
  },
};
