import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteOrganization, getOrganizations, resetState } from "../features/organization/organizationSlice"; 
import CustomModal from "../components/CustomModal";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Organization Name",
    dataIndex: "nameOfOrg",
  },
  {
    title: "Type",
    dataIndex: "typeOfOrg",
  },
  {
    title: "Email",
    dataIndex: "emailOfOrg",
  },
  {
    title: "Expiration Date",
    dataIndex: "expireDate",
    render: (text, record) => {
      const updatedAt = new Date(record.updatedAt); // Ensure updatedAt is a Date object
      
      // Extract integer value from expireDate (e.g., "365d" -> 365)
      const expireDays = parseInt(record.expireDate) || 0; // Default to 0 if invalid
      
      updatedAt.setDate(updatedAt.getDate() + expireDays); // Add expireDays to updatedAt

      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return updatedAt.toLocaleDateString(undefined, options); // Format the date as needed
    },
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const OrganizationList = () => {
  const [open, setOpen] = useState(false);
  const [organizationId, setOrganizationId] = useState("");

  const showModal = (id) => {
    setOpen(true);
    setOrganizationId(id);
  };

  const hideModal = () => {
    setOpen(false);
  };
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(resetState());
    dispatch(getOrganizations());
  }, [dispatch]);

  const organizationState = useSelector((state) => state.organization);
console.log(organizationState);
  const organizations = organizationState?.organization || []; // Default to an empty array

  // Ensure organizations is an array
  const dataSource = Array.isArray(organizations) ? organizations.map((org, index) => ({
    key: index + 1,
    nameOfOrg: org.nameOfOrg,
    typeOfOrg: org.typeOfOrg,
    emailOfOrg: org.emailOfOrg,
    updatedAt: org.updatedAt, // Include updatedAt for date calculation
    expireDate: org.expireDate, // Include expireDate for date calculation
    action: (
      <>
        <Link
          to={`/admin/add-Organization/${org._id}`} // Adjust the route as needed
          className="fs-3 text-danger"
        >
          <BiEdit />
        </Link>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showModal(org._id)}
        >
          <AiFillDelete />
        </button>
      </>
    ),
  })) : []; // Fallback to an empty array if not an array

  const handleDeleteOrganization = (id) => {
    dispatch(deleteOrganization(id)).then(() => {
      dispatch(getOrganizations());
    });
    setOpen(false);
  };
  

  return (
    <div>
      <h3 className="mb-4 title">Organization List</h3>
      <div>
        <Table columns={columns} dataSource={dataSource} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => {
          handleDeleteOrganization(organizationId);
        }}
        title="Are you sure you want to delete this organization?"
      />
    </div>
  );
};

export default OrganizationList;
