import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import organizationService from "./organizationService";

// Initial state
const initialState = {
  organizations: [],
  organization: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create organization
export const createOrganization = createAsyncThunk(
  "organization/create",
  async (organizationData, thunkAPI) => {
    try {
      return await organizationService.createOrganization(organizationData);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get organization by ID
export const getOrganizations = createAsyncThunk(
  "organizations/getOrganizations",
  async (thunkAPI) => {
    try {
      return await organizationService.getOrganizations();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get organization by ID
export const getOrganization = createAsyncThunk(
  "organization/get",
  async (id, thunkAPI) => {
    try {
      return await organizationService.getOrganization(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update organization
export const updateOrganization = createAsyncThunk(
  "organization/update",
  async ({ id, organizationData }, thunkAPI) => {
    try {
      return await organizationService.updateOrganization(id, organizationData);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete organization
export const deleteOrganization = createAsyncThunk(
  "organization/delete",
  async (id, thunkAPI) => {
    try {
      return await organizationService.deleteOrganization(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Organization slice
export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.createdOrganization = false;
      state.updatedOrganization = false;
      state.message = "";
      state.organization = [];
      state.organizations= [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createdOrganization = true;
        state.organizations.push(action.payload);
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOrganizations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(getOrganizations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(getOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.updatedOrganization = true;
        state.organization = action.payload;
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = state.organization.filter(
          (org) => org._id !== action.payload.id
        );
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// Export resetState action
export const { resetState } = organizationSlice.actions;

export default organizationSlice.reducer;
