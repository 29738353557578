import axios from "axios";
import { base_url } from "../../utils/baseUrl";

// Create organization
const createOrganization = async (organizationData) => {
  const response = await axios.post(`${base_url}/admin-user`, organizationData);
  return response.data;
};

const getOrganizations = async () => {
  const response = await axios.get(`${base_url}/admin-users`);
  return response.data;
};
// Get a single organization by ID
const getOrganization = async (id) => {
  const response = await axios.get(`${base_url}/admin-user/${id}`);
  return response.data;
};

// Update organization
const updateOrganization = async (id, organizationData) => {
  const response = await axios.put(`${base_url}/admin-user/${id}`, organizationData);
  return response.data;
};

// Delete organization
const deleteOrganization = async (id) => {
  const response = await axios.delete(`${base_url}/admin-user/${id}`);
  return response.data;
};

// Export all the functions
const organizationService = {
  createOrganization,
  getOrganizations,
  getOrganization,
  updateOrganization,
  deleteOrganization,
};

export default organizationService;
