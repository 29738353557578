// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import organizationSlice from "../features/organization/organizationSlice";



export const store = configureStore({
  reducer: {
    auth: authReducer,
    organization:organizationSlice
  },
});
