import React, { useState } from "react";
import CustomInput from "../components/CustomInput";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { base_url } from "../utils/baseUrl";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../features/auth/authActions";

const Login = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // State for form data
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // State for error message
  const [errorMessage, setErrorMessage] = useState("");

  // State for loader
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader

    try {
      // Make API call to login endpoint
      const response = await axios.post(
        base_url + "/users/login",
        formData
      );

      // Handle success
      dispatch(loginSuccess(response.data.token));
      // Set token in local storage for future authenticated requests
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("redirectUrl", response.data.redirectUrl);


      // Redirect to "/admin" route
      navigate(response.data.redirectUrl);

    } catch (error) {
      // Handle error
      console.error("Login error:", error.response.data);
      setErrorMessage("Invalid email or password");
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  // Function to handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="py-5" style={{ background: "#ffd333", minHeight: "100vh" }}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="my-5 w-25 bg-white rounded-3 mx-auto p-4">
        <h3 className="text-center title">Login</h3>
        <p className="text-center">Login to your account to continue.</p>
        <div className="error text-center">{errorMessage}</div>
        <form onSubmit={handleSubmit}>
          <CustomInput
            type="text"
            label="Email Address"
            i_id="email"
            i_class=""
            name="email"
            val={formData.email}
            onChng={handleChange}
            onBlr={() => {}}
          />
          <CustomInput
            type="password"
            label="Password"
            i_id="password"
            i_class=""
            name="password"
            val={formData.password}
            onChng={handleChange}
            onBlr={() => {}}
          />
          <div className="mb-3 text-end">
            <Link to="forgot-password" className="">
              Forgot Password?
            </Link>
          </div>
          <button
            className="border-0 px-3 py-2 text-white fw-bold w-100 text-center text-decoration-none fs-5"
            style={{ background: "#ffd333", position: "relative" }}
            type="submit"
            disabled={isLoading} // Disable button when loading
          >
            {isLoading && (
              <div className="loader"></div>
            )}
            {isLoading ? "" : "Login"} {/* Show loader text when loading */}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
