// PrivateRoute.js
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

function Protected(props) {
  const { Component } = props;
  const navigate = useNavigate();
  
  useEffect(()=>{
    let isAuthenticated = localStorage.getItem('token') && localStorage.getItem('redirectUrl');
    if(!isAuthenticated){
      navigate("/");
    }
  });
  return (
    <div>
      <Component/>
    </div>
  )
}

export default Protected;
