import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createOrganization, getOrganization, resetState, updateOrganization } from "../features/organization/organizationSlice";
import { toast } from "react-toastify";
import CustomInput from "../components/CustomInput";
import moment from "moment";

let schema = yup.object().shape({
  nameOfOrg: yup.string().required("Organization Name is Required"),
  typeOfOrg: yup.string().required("Type of Organization is Required"),
  emailOfOrg: yup.string().email("Invalid email format").required("Email is Required"),
  password: yup.string().required("Password is Required"),
  phoneOfOrg: yup.string().required("Phone number is Required"),
  addressOfOrg: yup.string().required("Address is Required"),
  cityOfOrg: yup.string().required("City is Required"),
  stateOfOrg: yup.string().required("State is Required"),
  pincodeOfOrg: yup.string().required("Pincode is Required"),
  nameOfadminOfOrg: yup.string().required("Admin Name is Required"),
  emailOfAdmin: yup.string().email("Invalid email format").required("Admin Email is Required"),
  phoneOfAdmin: yup.string().required("Admin Phone number is Required"),
  expireDate: yup.date().required("Expire Date is Required"),
});

const AddOrganization = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const getOrganizationId = location.pathname.split("/")[3];

  const organizationState = useSelector((state) => state.organization);

  const { isSuccess, isError, createdOrganization, updatedOrganization, organization } = organizationState;
  useEffect(() => {
    if (getOrganizationId) {
      dispatch(getOrganization(getOrganizationId));
    } else {
      dispatch(resetState());
    }
  }, [getOrganizationId, dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error("Something went wrong!");
    } else if (isSuccess && createdOrganization) {
      toast.success("Organization Added Successfully!");
      dispatch(resetState());
      navigate("/admin/Organization-list");
      
    } else if (isSuccess && updatedOrganization) {
      toast.success("Organization Updated Successfully!");
      dispatch(resetState());
      navigate("/admin/Organization-list");
      
    }
  }, [isSuccess, isError, createdOrganization, updatedOrganization, dispatch, navigate]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameOfOrg: organization.nameOfOrg || "",
      typeOfOrg: organization.typeOfOrg || "",
      emailOfOrg: organization.emailOfOrg || "",
      password: "",
      phoneOfOrg: organization.phoneOfOrg || "",
      addressOfOrg: organization.addressOfOrg || "",
      cityOfOrg: organization.cityOfOrg || "",
      stateOfOrg: organization.stateOfOrg || "",
      pincodeOfOrg: organization.pincodeOfOrg || "",
      nameOfadminOfOrg: organization.nameOfadminOfOrg || "",
      emailOfAdmin: organization.emailOfAdmin || "",
      phoneOfAdmin: organization.phoneOfAdmin || "",
      expireDate: organization.expireDate || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const currentDate = moment();
      const expireDate = moment(values.expireDate);
      const daysDifference = expireDate.diff(currentDate, "days");

      const formData = {
        nameOfOrg: values.nameOfOrg,
        typeOfOrg: values.typeOfOrg,
        emailOfOrg: values.emailOfOrg,
        password: values.password,
        phoneOfOrg: values.phoneOfOrg,
        addressOfOrg: values.addressOfOrg,
        cityOfOrg: values.cityOfOrg,
        stateOfOrg: values.stateOfOrg,
        pincodeOfOrg: values.pincodeOfOrg,
        nameOfadminOfOrg: values.nameOfadminOfOrg,
        emailOfAdmin: values.emailOfAdmin,
        phoneOfAdmin: values.phoneOfAdmin,
        expireDate: `${daysDifference}d`, // Send the number of days as required by the backend
      };

      if (getOrganizationId) {
        const data = { id: getOrganizationId, organizationData: formData };
        dispatch(updateOrganization(data));
      } else {
        dispatch(createOrganization(formData));
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">{getOrganizationId !== undefined ? "Edit" : "Add"} Organization</h3>
      <div className="">
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Organization Name"
              name="nameOfOrg"
              onChng={formik.handleChange("nameOfOrg")}
              onBlr={formik.handleBlur("nameOfOrg")}
              val={formik.values.nameOfOrg}
            />
            <div className="error">{formik.touched.nameOfOrg && formik.errors.nameOfOrg}</div>
          </div>

          <div className="mt-4">
          <select
            name="typeOfOrg"
            onChange={formik.handleChange("typeOfOrg")}
            onBlur={formik.handleBlur("typeOfOrg")}
            value={formik.values.typeOfOrg}
            className="form-control py-3 mt-3"

          >
            <option value="">Select Type Of Organization</option>
            <option value="group">Group</option>
            <option value="hospital">Hospital</option>
            <option value="pharmacy">Pharmacy</option>
            <option value="lab">Lab</option>
          
          </select>
            <div className="error">{formik.touched.typeOfOrg && formik.errors.typeOfOrg}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Organization Email"
              name="emailOfOrg"
              onChng={formik.handleChange("emailOfOrg")}
              onBlr={formik.handleBlur("emailOfOrg")}
              val={formik.values.emailOfOrg}
            />
            <div className="error">{formik.touched.emailOfOrg && formik.errors.emailOfOrg}</div>
          </div>
          <div className="mt-4">
            <CustomInput
              type="password"
              label="Enter Organization Password"
              name="password"
              onChng={formik.handleChange("password")}
              onBlr={formik.handleBlur("password")}
              val={formik.values.password}
            />
            <div className="error">{formik.touched.password && formik.errors.password}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Organization Phone"
              name="phoneOfOrg"
              onChng={formik.handleChange("phoneOfOrg")}
              onBlr={formik.handleBlur("phoneOfOrg")}
              val={formik.values.phoneOfOrg}
            />
            <div className="error">{formik.touched.phoneOfOrg && formik.errors.phoneOfOrg}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Organization Address"
              name="addressOfOrg"
              onChng={formik.handleChange("addressOfOrg")}
              onBlr={formik.handleBlur("addressOfOrg")}
              val={formik.values.addressOfOrg}
            />
            <div className="error">{formik.touched.addressOfOrg && formik.errors.addressOfOrg}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter City"
              name="cityOfOrg"
              onChng={formik.handleChange("cityOfOrg")}
              onBlr={formik.handleBlur("cityOfOrg")}
              val={formik.values.cityOfOrg}
            />
            <div className="error">{formik.touched.cityOfOrg && formik.errors.cityOfOrg}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter State"
              name="stateOfOrg"
              onChng={formik.handleChange("stateOfOrg")}
              onBlr={formik.handleBlur("stateOfOrg")}
              val={formik.values.stateOfOrg}
            />
            <div className="error">{formik.touched.stateOfOrg && formik.errors.stateOfOrg}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Pincode"
              name="pincodeOfOrg"
              onChng={formik.handleChange("pincodeOfOrg")}
              onBlr={formik.handleBlur("pincodeOfOrg")}
              val={formik.values.pincodeOfOrg}
            />
            <div className="error">{formik.touched.pincodeOfOrg && formik.errors.pincodeOfOrg}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Admin Name"
              name="nameOfadminOfOrg"
              onChng={formik.handleChange("nameOfadminOfOrg")}
              onBlr={formik.handleBlur("nameOfadminOfOrg")}
              val={formik.values.nameOfadminOfOrg}
            />
            <div className="error">{formik.touched.nameOfadminOfOrg && formik.errors.nameOfadminOfOrg}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Admin Email"
              name="emailOfAdmin"
              onChng={formik.handleChange("emailOfAdmin")}
              onBlr={formik.handleBlur("emailOfAdmin")}
              val={formik.values.emailOfAdmin}
            />
            <div className="error">{formik.touched.emailOfAdmin && formik.errors.emailOfAdmin}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="text"
              label="Enter Admin Phone"
              name="phoneOfAdmin"
              onChng={formik.handleChange("phoneOfAdmin")}
              onBlr={formik.handleBlur("phoneOfAdmin")}
              val={formik.values.phoneOfAdmin}
            />
            <div className="error">{formik.touched.phoneOfAdmin && formik.errors.phoneOfAdmin}</div>
          </div>

          <div className="mt-4">
            <CustomInput
              type="date"
              label="Enter Expire Date"
              name="expireDate"
              onChng={formik.handleChange("expireDate")}
              onBlr={formik.handleBlur("expireDate")}
              val={formik.values.expireDate}
            />
            <div className="error">{formik.touched.expireDate && formik.errors.expireDate}</div>
          </div>

          <button className="btn btn-success border-0 rounded-3 my-5" type="submit">
            {getOrganizationId !== undefined ? "Edit" : "Add"} Organization
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddOrganization;
